import React from 'react'
import { PageProps } from 'gatsby'
import { useApi } from '~/api'
import {
  Head,
  Footer,
  Banner,
  Benefits,
  Insurances,
  Feedbacks,
} from '~/components'
import imgTeste from '~/assets/img/img-banner-seguros.jpg'
import imgMob from '~/assets/img/img-teste-mobile.jpg'

const Seguros = ({ location, pageContext }: PageProps) => {
  const context = useApi(pageContext, 'page-home')

  const { safes, testimonials } = context

  return (
    <>
      <Head />
      <main>
        <Banner
          title="Seguros"
          img={imgTeste}
          mobile={imgMob}
          location={'/seguros/'}
        />
        <Benefits location={'/seguros/'} />
        <Insurances
          location={location}
          title="Conheça os
          seguros que
          a Dimas Seguros oferece"
          data={safes}
        />
        {testimonials.length < 1 ? (
          <div className="padding-custom"></div>
        ) : (
          <></>
        )}
        <Feedbacks marginNegative data={testimonials} />
      </main>
      <Footer />
    </>
  )
}

export default Seguros
